/** @jsx jsx */
import { css } from "@emotion/react";
import bnccShape from '../images/LP-versar/conectando-shape.png'

export const conectandoStyles = css`
  background-color: #E8E8DB;
  scroll-margin-top: 200px;
  overflow: clip;
  /* background-image: url(${bnccShape});
  background-position: right;
  background-repeat: no-repeat;
  object-fit: contain;
  background-size: cover;
  background-position-x: right; */

  @media (max-width: 850px) {
    .col-image {
      padding: 0;
      margin-bottom: 40px;
    }
    .text-container {
      margin-bottom: 93px;
    }
  }

  .row {
    align-items: center;
  }

  img {
    width: 100%;
  }

  .col-text {
    display: flex;
    justify-content: center;
  }

  .text-container {
    max-width: 380px;
    h1 {
      color: #375F92;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      letter-spacing: -0.8px;
      margin-bottom: 32px;
    }
    
    p {
      color: #1E3854;
      font-size: 16px;
      font-weight: 500;
      line-height: 150%; 
    }
  }
`

