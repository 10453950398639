/** @jsx jsx */
import { css } from "@emotion/react";

import OjeitoShape from '../images/LP-versar/sociomeconal-shape.png'
import OjeitoShapeMobile from '../images/LP-versar/socioemocional-shape-mobile.svg'

export const oJeitoVersar = css`
background-color: #E8E8DB;
display: flex;
flex-direction: column;
padding-top: 104px;
padding-bottom: 153px;
background-image: url(${OjeitoShape});
background-repeat: no-repeat;
background-size: contain;
background-position-x: 526px;
overflow: clip;

@media (max-width: 850px) {
  background-image: url(${OjeitoShapeMobile});
  background-position-x: center;
  background-size: contain;
  background-repeat: repeat;
}

.title-container {
  display: flex;
  justify-content: center;
  margin-bottom: 56px;
  width: 100%;
  .title {
    color: #375F92;
    font-size: 40px;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -1.2px;
    text-align: center;
    span {
      font-weight: 800 !important;
    }
  }
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 37px;
  justify-content: center;
  width: 100%;
}
  
`
