/** @jsx jsx */
import { css } from "@emotion/react";

export const container = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 280px;
  width: 100%;
`

export const cardStyles = css`
display: flex;
flex-direction: column;
background-color: #fff;
border-radius: 16px;
max-height: 304px;
min-height: 280px;
width: 100%;
position: absolute;
padding: 32px 25px 32px 25px;

.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}

h3 {
  color: #375F92;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.64px;
  white-space: nowrap;
}

p {
  color: #375F92;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  text-align: left;
}

img {
  width: 59px;
  height: 49px;
}


`
