/** @jsx jsx */
import { css } from "@emotion/react";
import carouselShape from '../images/LP-versar/carousel-shape.png'

export const carouselContentStyles = css`
align-items: center;
background-color: #E8E8DB;
display: flex;
flex-direction: column;
justify-content: center;
padding: 68px 0px 99px 0px;
background-image: url(${carouselShape});
background-repeat: no-repeat;
background-position: bottom;
background-size: cover;
.carouselMobile{
  display: none;
}

@media (max-width: 850px) {
  background-image: none;
  .carouselMobile {
    display: block;
    width: 100%;
  }
  .carouselDesktop {
    display: none;
  }
}
  h1 {
    color: #375F92;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.8px;
    max-width: 730px;
    margin-bottom: 74px;
  }
`
