/** @jsx jsx */
import { jsx } from "@emotion/react";
import Select from 'react-select'
import { formSectionStyles, input, form } from "../assets/styles/FormSection.styles";
import { Col, Container, Row } from "react-bootstrap";


const HowToWork = () => {

  return (
    <section id='contato' css={formSectionStyles}>
      <Container>
        <Row>
          <Col data-aos="fade-left" data-aos-delay="500">
            <Container className="form-container">
              <div id="formulario" className="form-div">
                <h1>Quero uma demonstração!</h1>
                <form
                  name='agendar-visita'
                  method='POST'
                  action='/success'
                  data-netlify='true'
                  css={form}
                >
                  <input
                    type='hidden'
                    name='form-name'
                    value='agendar-visita'
                  />
                  <div className='text_input_containe'>
                    <div>
                      <input
                        className='text_input'
                        type="text"
                        name="nome"
                        placeholder="Nome"
                        required
                      />
                    </div>
                    <div css={input}>
                      <input
                        className='text_input'
                        type="mail"
                        name="email"
                        placeholder="Email"
                        required
                      />
                    </div>
                    <div css={input}>
                      <input
                        className='text_input'
                        type="tel"
                        name="telefone"
                        placeholder="Telefone"
                        required
                      />
                    </div>
                  </div>
                  <div className='role-container'>
                    <div className='roles-checkbox'>
                    <span>Cargo:</span>
                      <div className='double_role'>
                        <div className='single-role'>
                          <label class="form-control">
                            <input type="checkbox" name="mantenedor"  />
                            Mantenedor(a)
                          </label>
                        </div>
                        <div className='single-role'>
                          <label class="form-control">
                            <input type="checkbox" name="gestor"  />
                            Gestor(a)
                          </label>
                        </div>
                      </div>
                      <div className='double_role'>
                        <div className='single-role'>
                          <label class="form-control">
                            <input type="checkbox" name="coordenador"  />
                            Coordenador(a)
                          </label>
                        </div>
                        <div className='single-role'>
                          <label class="form-control">
                            <input type="checkbox" name="professor"  />
                            Professor(a)
                          </label>
                        </div>
                      </div>
                      <input
                        className='other'
                        type="other_role"
                        name="other_role"
                        placeholder="Outro"
                      />
                    </div>
                  </div>
                  <button type='submit'>
                    Enviar
                  </button>
                </form>
              </div>
            </Container>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HowToWork;
