/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import { videoSectionStyles } from '../assets/styles/VideoSection.styles'
import videoLego from '../assets/video/video-lego-resized.mp4'
import Video from './video'

const VideoSection = () => {
  return (
    <section css={videoSectionStyles}>
      <Video videoSrcURL={videoLego} />
    </section>
  )
}

export default VideoSection
