/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from "@emotion/react";
import IconLinkedin from '../assets/images/LP-versar/linkedin.svg'
import IconInstagram from "@/images/LP-versar/instagram.svg";
import IcoFacebook from "@/images/LP-versar/facebook.svg";
import { Link } from "gatsby";
import { footerStyles } from '../assets/styles/Footer.styles';
import logoFooter from '../assets/images/LP-versar/footer-logo.png'
import fractalLogo from '../assets/images/LP-versar/fractal-logo.svg'

const AboutTwo = () => {
  return (
    <section css={footerStyles}>
      <Container>
        <Row className='row-logo' data-aos="fade-down" data-aos-delay="300">
          <Col className='logo-col'>
            <Link href="/">
              <img src={logoFooter} alt='Logo do versar' />
            </Link>
          </Col>
          <Col className='social-medial-col'>
            <div className='links-title'>
              <Link href="/#sobre">Sobre nós </Link>
              <Link href="/#contato">Fale conosco</Link>
            </div>
            <div className='icons'>
              <Link href="https://www.instagram.com/versar.socioemocional/" target='_blank'>
                <img src={IconInstagram} alt="icone do instagram" />
              </Link>
              <Link href="https://www.facebook.com/versar.socioemocional" target='_blank'>
                <img src={IcoFacebook} alt="icone do facebook" />
              </Link>
              <Link href="https://www.linkedin.com/company/versar-socioemocional/" target='_blank'>
                <img src={IconLinkedin} alt="icone do linkedin" />
              </Link>
            </div>
          </Col>
        </Row>
        <Row className='developed-by'>
          <div>
            <span>
              Desenvolvido por:
              <Link to='https://fractaltecnologia.com.br/' target='_blank'>
                <img src={fractalLogo} alt="Logo da fractal" />
              </Link>
            </span>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default AboutTwo;
