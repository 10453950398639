/** @jsx jsx */
import { css } from "@emotion/react";

export const VersarParaIrAlemStyles = css`
  background-color: #F3D6C8;
  padding: 72px 0px 64px 0px;
  overflow: clip;

  .row {
    align-items: center;
  }

  @media (max-width: 850px) {
    img {
      width: 100%;
      margin-bottom: 87px;
    }
    p {
      width: 100% !important;
    }
  }

  h2 {
    color: #8A588D;
    font-size: 40px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.8px;
    margin-bottom: 24px;
  }
  p {
    color: #1E3854;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 40px;
    width: 380px;
  }

  a {
    background-color: #EF7B8E;
    color: #FFF !important;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.32px;
    text-transform: uppercase;
    padding: 15px 32px;
    width: fit-content;
    cursor: pointer;
  }

  a:hover {
    background-color: #8A588D;
  }

  a:active {
    background-color: #375F92;
  }

  .text-container {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .content {
    display: flex;
    flex-direction: column;
  }
`