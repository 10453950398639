/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/react";
import { cardStyles, container } from '../assets/styles/CardWhatIsMobile.styles';


const CardWhatIsMobile = ({ icon, title, text, isNot }) => {

  return (

    <div css={container}>
      <div css={cardStyles}>
        <div className='title'>
          <h3 style={{ color: isNot ? '#8A588D' : '#375F92' }}>
            {title}
          </h3>
          <img style={{ right: isNot ? '-23px' : '-75px ' }} src={icon} />
        </div>
        <div>
          <p style={{ color: isNot ? '#8A588D' : '#375F92' }}>{text}</p>
        </div>
      </div>
    </div>

  )
}

export default CardWhatIsMobile;
