/** @jsx jsx */
import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"

import { jsx } from "@emotion/react"
import { useMediaQuery } from 'react-responsive'
import { oJeitoVersar } from '../assets/styles/OJeitoVersar.styles'
import Card from './card'

import ParaEscola from '../assets/images/LP-versar/para-escola.png'
import ParaOEstudante from '../assets/images/LP-versar/para-o-estudante.png'
import ParaFamilia from '../assets/images/LP-versar/para-familia.png'
import ParaSociedade from '../assets/images/LP-versar/para-sociedade.png'

const OJeitoVersar = () => {

  return (
    <section css={oJeitoVersar}>
      <Container fluid>
        <div className='title-container'>
          <h2 data-aos="fade-down" data-aos-delay="500" className='title'>
            O jeito Versar de desenvolver o <br /> <span>socio<i>emocional</i></span>
          </h2>
        </div>
        <div className='cards-container'>
          <div data-aos="fade-left" data-aos-delay="500">
            <Card
              img={ParaEscola}
              title='Para a escola'
              text='uma ferramenta para disseminar sua cultura.'
            />
          </div>
          <div data-aos="fade-right" data-aos-delay="600">
            <Card
              img={ParaOEstudante}
              title='Para o estudante'
              text='um jeito de aprender que o torna mais seguro, independente e determinado.'
            />
          </div>
          <div data-aos="fade-left" data-aos-delay="700">
            <Card
              img={ParaFamilia}
              title='Para a família'
              text='uma oportunidade de fortalecer os vínculos com a Escola.'
            />
          </div>
          <div data-aos="fade-right" data-aos-delay="800">
            <Card
              img={ParaSociedade}
              title='Para a sociedade'
              text='um compromisso com uma formação plena — de gente inteligente, saudável e feliz.'
            />
          </div>
        </div>
      </Container>
    </section>
  )
}

export default OJeitoVersar
