/** @jsx jsx */
import { jsx } from '@emotion/react'
import { videoInstitutional } from '../assets/styles/VideoInstitutional.styles'

import videoDesktop from '../assets/video/video-desktop.mp4'
import videoManifestoDesktop from '../assets/video/versar-manifesto-desktop.mp4'
import VideoControlled from './controlledVideo'

const VideoInstitutional = () => {
  return (

    <section id='sobre' css={videoInstitutional}>
      <div data-aos="fade-down" data-aos-delay="300" className='title-container'>
        <h2>Autoral, modular e flexível</h2>
        <p>Junte-se ao nosso time de escolas autorais. Use o Versar para desenvolver sua própria abordagem, para lidar com os próprios desafios e torne-se uma autoridade no assunto — com sua própria voz. </p>
      </div>
      <div data-aos="fade-up" data-aos-delay="400" className='video-institutional'>
        <VideoControlled videoSrcURL={videoManifestoDesktop} />
      </div>
      <div  className='text-container'>
        <p data-aos="fade-up" data-aos-delay="300">
          Ter o Versar na sua escola é ter a possibilidade de <span className='full-text'>nunca mais ter que ouvir falar em <span className='socioemocional'>socio<i>emocional</i></span> na sua vida.</span>
        </p>
      </div>
    </section>

  )
}

export default VideoInstitutional
