/** @jsx jsx */
import { jsx } from '@emotion/react'

import { containerCard } from '../assets/styles/CardCarouselMobile.styles.js'
import 'swiper/swiper-bundle.min.css'
import 'swiper/css/pagination';
import 'swiper/css'
import 'swiper/css/navigation'
import { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import ArrowCheckMark from '../assets/images/LP-versar/arrow-check-mark.svg'
import ErrorIcon from '../assets/images/LP-versar/error-icon.svg'
import CardWhatIsMobile from './CardWhatIsMobile.js';

function CardCarouselSwiperMobile() {
  return (
    <div css={containerCard}>
      <Swiper
        data-aos="fade-left" data-aos-delay="500"
        slidesPerView={'auto'}
        spaceBetween={0}
        loop={true}
        modules={[Navigation, Pagination]}
        pagination={{
          clickable: true,
        }}
        navigation={{
          prevEl: '.slider-prev',
          nextEl: '.slider-next',
        }}
        breakpoints={{
          851: {
            spaceBetween: 24,
          },
        }}
        className='swiper'
      >
        <SwiperSlide>
          <div className='swiper-slide__container'>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
            >
              <CardWhatIsMobile icon={ArrowCheckMark} title='O que é' text='Aprimora o desempenho acadêmico e aprofunda o engajamento com o conteúdo' />
              <CardWhatIsMobile isNot icon={ErrorIcon} title='O que não é' text='Autoajuda' />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='swiper-slide__container'>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
            >
              <CardWhatIsMobile icon={ArrowCheckMark} title='O que é' text='Constrói relacionamentos e desenvolve habilidades que ajudam a lidar com a realidade' />
              <CardWhatIsMobile isNot icon={ErrorIcon} title='O que não é' text='Terapia' />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='swiper-slide__container'>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
            >
              <CardWhatIsMobile icon={ArrowCheckMark} title='O que é' text='Ajuda os alunos a compreender diferentes perspectivas e compartilhar ideias e visões de mundo' />
              <CardWhatIsMobile isNot icon={ErrorIcon} title='O que não é' text='Agenda política' />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='swiper-slide__container'>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
            >
              <CardWhatIsMobile icon={ArrowCheckMark} title='O que é' text='Ajuda os alunos a compreender diferentes perspectivas e compartilhar ideias e visões de mundo' />
              <CardWhatIsMobile isNot icon={ErrorIcon} title='O que não é' text='Agenda política' />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='swiper-slide__container'>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
            >
              <CardWhatIsMobile icon={ArrowCheckMark} title='O que é' text='Atende às demandas locais, definidas por escola, família e comunidade' />
              <CardWhatIsMobile isNot icon={ErrorIcon} title='O que não é' text='Modelo único aplicável a todas as escolas' />
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default CardCarouselSwiperMobile