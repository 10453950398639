/** @jsx jsx */
import React from 'react'
import { jsx } from "@emotion/react"
import { cardStyles } from '../assets/styles/Card.styles'

const Card = ({ img, title, text }) => {
  return (
    <div css={cardStyles}>
      <img src={img} />
      <h3>{title}</h3>
      <p>{text}</p>
    </div>
  )
}

export default Card
