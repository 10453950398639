import React from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import HeaderOne from '../components/header-one-success'
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import SuccessContent from "@/components/SuccessContent";

const Success = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Versar">
          <HeaderOne />
           <SuccessContent />
          <Footer /> 
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default Success;
