/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/react";
import { carouselContentStyles } from '../assets/styles/CarouselContent.styles';
import CardCarouselSwiper from './CardCarouselSwiper';
import CardCarouselSwiperMobile from './CardCarouselSwiperMobile';

const CarouselContent = () => {
  return (
    <section css={carouselContentStyles}>
      <h1 data-aos="fade-down" data-aos-delay="300">O Versar não é um produto separado da escola</h1>
      <div className='carouselMobile'>
        <CardCarouselSwiperMobile />
      </div>
      <div className='carouselDesktop'>
        <CardCarouselSwiper />
      </div>
    </section>
  )
}

export default CarouselContent;
