/** @jsx jsx */
import React from "react";

import { jsx } from "@emotion/react";
import { conectandoStyles } from "../assets/styles/ConectandoBNCC.style";
import { Row, Container, Col } from 'react-bootstrap'
import ConectandoBNCCImage from '../assets/images/LP-versar/conectando-bncc.png'

const ConectandoBNCC = () => {
  return (
    <section id='bncc' css={conectandoStyles}>
      <Container>
        <Row >
          <Col data-aos="fade-right" data-aos-delay="300" className='col-image' lg={6} md={12} sm={12}>
            <img src={ConectandoBNCCImage} />
          </Col>
          <Col data-aos="fade-left" data-aos-delay="500" className='col-text' lg={6} md={12} sm={12}>
            <div className='text-container'>
              <h1>Conectado à BNCC: a escola existe para ir além</h1>
              <p>
                A Base Nacional Comum Curricular (BNCC) propõe que se adotem estratégias de aprendizagem que considerem o desenvolvimento global do ser humano. É preciso romper com visões reducionistas que tratem a escola apenas como repositório de conteúdo acadêmico.
              </p>
              <p>
              Assim como é indispensável pensar no socioemocional não como mera disciplina de uma grade, mas como conjunto de práticas, crenças e vivências da escola.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ConectandoBNCC;
