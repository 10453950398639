/** @jsx jsx */
import { jsx } from '@emotion/react'

import { containerCard } from '../assets/styles/CardCarousel.styles.js'
import 'swiper/swiper-bundle.min.css'
import 'swiper/css/pagination';
import 'swiper/css'
import 'swiper/css/navigation'
import { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import LeftArrow from '../assets/images/LP-versar/arrow-left.svg'
import RightArrow from '../assets/images/LP-versar/arrow-right.svg'
import ArrowCheckMark from '../assets/images/LP-versar/arrow-check-mark.svg'
import ErrorIcon from '../assets/images/LP-versar/error-icon.svg'
import CardWhatIs from './CardWhatIs.js';

function CardCarouselSwiper() {
  return (
    <div css={containerCard}>
      <Swiper
        data-aos="fade-left" data-aos-delay="500"
        slidesPerView={'auto'}
        slidesPerGroup={2}
        spaceBetween={0}
        loop={true}
        modules={[Navigation, Pagination]}
        pagination={{
          clickable: true,
        }}
        navigation={{
          prevEl: '.slider-prev',
          nextEl: '.slider-next',
        }}
        breakpoints={{
          851: {
            spaceBetween: 30,
          },
        }}
        className='swiper'
      >
        <SwiperSlide>
          <div className='swiper-slide__container'>
            <CardWhatIs icon={ArrowCheckMark} title='O que é' text='Aprimora o desempenho acadêmico e aprofunda o engajamento com o conteúdo' />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='swiper-slide__container'>
            <CardWhatIs isNot icon={ErrorIcon} title='O que não é' text='Autoajuda' />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='swiper-slide__container'>
            <CardWhatIs icon={ArrowCheckMark} title='O que é' text='Constrói relacionamentos e desenvolve habilidades que ajudam a lidar com a realidade' />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='swiper-slide__container'>
            <CardWhatIs isNot icon={ErrorIcon} title='O que não é' text='Terapia' />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='swiper-slide__container'>
            <CardWhatIs icon={ArrowCheckMark} title='O que é' text='Ajuda os alunos a compreender diferentes perspectivas e compartilhar ideias e visões de mundo' />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='swiper-slide__container'>
            <CardWhatIs isNot icon={ErrorIcon} title='O que não é' text='Agenda política' />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='swiper-slide__container'>
            <CardWhatIs icon={ArrowCheckMark} title='O que é' text='Atende às demandas locais e são definidas pela escola, família e comunidade' />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='swiper-slide__container'>
            <CardWhatIs isNot icon={ErrorIcon} title='O que não é' text='Modelo único aplicável a todas as escolas' />
          </div>
        </SwiperSlide>
      </Swiper>

      <button className='btn slider-prev'>
        <div className='btn__icon'>
          <img src={LeftArrow} />
        </div>
      </button>

      <button className='btn slider-next'>
        <div className='btn__icon'>
          <img src={RightArrow} />
        </div>
      </button>
    </div>
  )
}

export default CardCarouselSwiper
