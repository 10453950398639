/** @jsx jsx */
import React, { useContext, Fragment, useRef } from "react";
import { Link } from "gatsby";
import { MenuContext } from "@/context/menu-context";
import { LogoImage, NavInnerLinks } from "@/data";
import { jsx } from "@emotion/react";
import Instagram from '@/images/LP-versar/instagram.svg'
import Facebook from '@/images/LP-versar/facebook.svg'
import Youtube from '@/images/LP-versar/youtube.svg'
import {
  showOverlayNav,
  popUpMenu,
  closepopUp,
  logo2,
  popUpInner,
  dlMenuWrap,
  subMenu,
  popUp,
  org,
  socialMedia
} from "../assets/styles/PopUpMenu.styles";

const PopupMenu = () => {
  const { menuStatus, updateMenuStatus } = useContext(MenuContext);
  const menuEl = useRef(null);
  const handleMenuClick = (e) => {
    e.preventDefault();
    updateMenuStatus(!menuStatus);
  };
  const handleMenuMobClick = () => {
    updateMenuStatus(!menuStatus);
  };
  return (
    <div className="show-overlay-nav" css={showOverlayNav}>
      <div className="popup popup__menu" css={[popUp, popUpMenu]}>
        <a
          href=""
          id="close-popup"
          onClick={handleMenuClick}
          css={closepopUp}
        ></a>
        <div className="container mobileContainer">
          <div className="row">
            <div className="col-lg-12 text-left">
              <div css={logo2}>
                <Link to="/">
                  <img src={LogoImage.light} alt="" />
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div css={popUpInner} className="popup-inner">
                <nav
                  css={dlMenuWrap}
                  className="popup-menu dl-menu__wrap text-center"
                  ref={menuEl}
                >
                  <ul>
                    {NavInnerLinks.map((links, index) => {
                      return (
                        <li
                          key={index}
                          className={`${undefined !== links.subItems
                              ? "menu-item-has-children"
                              : ""
                            }`}
                        >
                          <Link to={links.url} onClick={handleMenuMobClick}>
                            {links.name}
                          </Link>
                          {undefined !== links.subItems ? (
                            <Fragment>
                              <button
                                onClick={(e) => {
                                  menuEl.current
                                    .querySelectorAll(".sub-menu")
                                    .forEach((item) => {
                                      item.classList.remove("show");
                                    });

                                  let clickedItem = e.currentTarget.parentNode;
                                  clickedItem
                                    .querySelector(".sub-menu")
                                    .classList.toggle("show");
                                }}
                              >
                                <i className="fa fa-angle-down"></i>
                              </button>
                              <ul css={subMenu} className="sub-menu">
                                {links.subItems.map((subLinks, index) => (
                                  <li key={index}>
                                    <Link to={subLinks.url}>
                                      {subLinks.name}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </Fragment>
                          ) : null}
                        </li>
                      );
                    })}
                  </ul>
                </nav>
                <div css={socialMedia}>
                  <nav className='nav-social'>
                    <a target='_blank' href='https://www.instagram.com/teamsix.elitemilitar/'>
                      <img src={Instagram} alt="Instagram" />
                    </a>
                    <a target='_blank' href='https://www.facebook.com/teamsix.elitemilitar'>
                      <img src={Facebook} alt="Facebook" />
                    </a>
                    <a target='_blank' href='https://www.youtube.com/@rede_bertoni'>
                      <img src={Youtube} alt="Youtube" />
                    </a>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupMenu;
