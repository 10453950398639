/** @jsx jsx */
import { jsx } from "@emotion/react"
import React, { useEffect, useState } from "react"

import videoDesktop from '../assets/video/video-desktop.mp4'
import videoMobile from '../assets/video/video-mobile.mp4'
import ArrowDown from '../assets/images/LP-versar/arrow-down.svg'
import { abAgency, customSection, mobile_container, videoBg } from '../assets/styles/SliderOne.styles'
import Video from './video'

const VideoHome = () => {
  const [isMobile, setIsMobile] = useState(false)
  const [userInteracted, setUserInteracted] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500)
    }

    const handleUserInteraction = () => {
      setUserInteracted(true)
    }

    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth <= 500)
      window.addEventListener('resize', handleResize)
      window.addEventListener('mousemove', handleUserInteraction)
      window.addEventListener('keydown', handleUserInteraction)
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize)
        window.removeEventListener('mousemove', handleUserInteraction)
        window.removeEventListener('keydown', handleUserInteraction)
      }
    }
  }, [])

  const handleMouseMove = () => {
    setUserInteracted(true)
  }

  return (
    <div onMouseMove={handleMouseMove}>
      {isMobile ? (
        <div css={mobile_container}>
          <video style={{ marginBottom: isMobile ? '-10px' : 0 }} width="100%" autoPlay muted loop playsInline>
            <source src={videoMobile} type="video/mp4" />
          </video>
          {userInteracted && (
            <div css={customSection}>
              <h1 data-aos="fade-right">
                Versar é trans<i>formar</i>
              </h1>
              <p data-aos="fade-left">
                Cultura Socioemocional para escolas autorais. Transforme emoções em competências, engaje as famílias,
                fortaleça a cultura e a performance acadêmica de sua escola.
              </p>
            </div>
          )}
          <div className="overlay" />
        </div>
      ) : (
        <section css={[abAgency, videoBg]}>
          {userInteracted && (
            <div css={customSection}>
              <h1 data-aos="fade-right">
                Versar é trans<i>formar</i>
              </h1>
              <p data-aos="fade-left">
                Cultura Socioemocional para escolas autorais. Transforme emoções em competências, engaje as famílias,
                fortaleça a cultura e a performance acadêmica de sua escola.
              </p>
              <img src={ArrowDown} alt="Seta para baixo" />
            </div>
          )}
          <div className="overlay" />
          <Video videoSrcURL={videoDesktop} />
        </section>
      )}
    </div>
  )
}

export default VideoHome
