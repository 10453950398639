/** @jsx jsx */
import { css } from '@emotion/react'

export const containerCard = css`
  align-items: center;
  column-gap: 8px;
  display: grid;
  grid-template-areas: 'btnPrev slider btnRight';
  grid-template-columns: auto minmax(auto, 790px) auto;
  margin: auto;
  max-width: 950px;
  padding: 0 8px;

  @media (max-width: 850px) {
    column-gap: 0;
    grid-template-areas:
      'slider slider slider'
      '_ btnPrev btnRight';
    padding: 0;
    row-gap: 40px;
  }
  .swiper {
    grid-area: slider;
    max-width: 1050px;
    width: 100%;
    height: 350px;
    &-slide { 
      height: 280px !important;
      max-width: 380px;
      padding-block: 20px;

      &__container {
        text-align: center;

        .icon {
          background-color: #4467a9;
          border-radius: 16px;
          height: 68px;
          margin: 0 auto 30px;
          padding: 10px;
          width: 68px;

          img {
            height: 100%;
            width: 100%;
          }
        }

        .text {
          h3 {
            font-size: 24px;
            font-weight: 600;
            line-height: 150%;
          }

          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;
          }
        }
      }

      @media (max-width: 850px) {
        &:first-of-type {
          margin-left: 37px;
        }

        &:last-of-type {
          margin-left: 8px;
          margin-right: 37px;
        }

        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5) {
          margin-left: 8px;
        }
      }

      @media (max-width: 500px) {
        &:first-of-type {
          margin-left: 16px;
        }

        &:last-of-type {
          margin-left: 8px;
          margin-right: 16px;
        }

        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5) {
          margin-left: 8px;
        }
      }
    }
  }

  .btn {
    align-items: center;
    border: none;
    cursor: pointer;
    display: flex;
    /* height: 50px; */
    justify-content: center;
    outline: none;
    /* width: 50px; */

    &__icon {
      width: 100%;

      img {
        width: 100%;
      }
    }

    @media (max-width: 850px) {
      height: 36px;
      width: 36px;
    }

    @media (max-width: 500px) {
      background: inherit;
    }
  }
  .btn:hover {
    path {
      fill: #0c79fe;
    }
  }

  .slider-prev {
    grid-area: btnPrev;
    

    @media (max-width: 850px) {
      justify-self: end;
      margin-right: 40px;
    
    }

    @media (max-width: 500px) {
      margin-right: 20px;
    }
  }

  .slider-next {
    grid-area: btnRight;
    justify-self: end;

    @media (max-width: 850px) {
      justify-self: start;
      margin-right: 58px;
    }

    @media (max-width: 500px) {
      margin-right: 20px;
    }
  }
  .swiper-pagination-bullet {
    height: 16px;
    width: 16px;
    background-color: #fff;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background-color: #8A588D;
  }
`
