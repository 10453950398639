import logoDefault from "../assets/images/LP-versar/logo.svg"

export const LogoImage = {
  light: logoDefault,
};

export const NavLinks = [
  {
    name: "SOBRE",
    url: "/#sobre",
  },
  {
    name: "BNCC",
    url: "/#bncc",
  },
  {
    name: "VERSAR NA ESCOLA",
    url: "/#versar-na-escola",
  },
  {
    name: "CONTATO",
    url: "/#contato",
  },
];

export const NavInnerLinks = [
  {
    name: "SOBRE",
    url: "/#sobre",
  },
  {
    name: "BNCC",
    url: "/#bncc",
  },
  {
    name: "NA ESCOLA",
    url: "/#versar-na-escola",
  },
  {
    name: "CONTATO",
    url: "/#contato",
  },
];
