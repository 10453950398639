/** @jsx jsx */
import { css } from '@emotion/react'
import { colors } from './layout.styles'

export const navLinksSection = css`
  display: flex !important;
  align-items: center;
  justify-content: center;

  a:first-child {
    margin-right: 61px;
  }
  @media (max-width: 500px) {
    display: none !important;
  }
`

export const socialMedia = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .nav-social {
    display: flex;
    align-items: center;
    @media (max-width: 500px) {
      svg {
        height: 30px;
        width: 30px;
      }
    }
  }
  a{
    margin-left: 8px;
    img{
      &:hover{
        filter: brightness(0) saturate(100%) invert(82%) sepia(60%) saturate(3772%) hue-rotate(302deg) brightness(96%) contrast(96%);
      }
    }
  }
  /* a:first-child {
    margin-right: 8px;
  } */
  /* a:nth-child(2) {
    margin-right: 24px;
    @media (max-width: 500px) {
      margin-right: 12px;
    }
  } */

  a:hover {
    path {
      stroke: #bc9bfa;
    }
    .facebookIcon {
      fill: #bc9bfa;
    }
  }
  a:active {
    path {
      stroke: #04aec9;
    }
    .facebookIcon {
      fill: #04aec9;
    }
  }

  .fale-btn {
    padding: 12px 22px;
    background-color: #6c63ff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
    color: #f9f9f9;

    &:hover {
      background-color: #bc9bfa;
    }
    &:active {
      background-color: #04aec9;
    }
  }
  .mobile {
    display: flex;
    padding: 12px 16px;
    @media (min-width: 850px) {
      display: none;
    }
  }
  .desktop {
    display: flex;
    @media (max-width: 850px) {
      display: none;
    }
  }
`

export const header = css`
  align-items: center;
  background: #375F92;
  display: flex;
  left: 0;
  padding: 40px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  transition: .3s;

  .justify-content-between{
    @media (max-width: 500px) {
      flex-wrap: nowrap;
    }
  }
  &.fixedHeader {
    background-color: #375F92;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    border: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  }
`

export const logo = css`
  text-align: left;
  /* img {
    max-width: 100%;
    height: auto;
    @media (min-width: 500px) {
      width: 180px;
    }
  } */
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 0;
  }
`

export const mainMenu = css`
  position: relative;
  height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

  @media (min-width: 500px) and (max-width: 850px) {
      display: flex;
      white-space: nowrap;
    }

  li:first-child {
    @media (min-width: 500px) and (max-width: 850px) {
      margin-left: 60px;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & li {
      list-style: none;
      display: inline-block;
      line-height: 150%;
      position: relative;
      padding: 0 24px;

      & > .active a {
        color: #1e1e1e;
        &:after {
          width: 100%;
        }
      }

      & a {
        display: block;
        color: #f9f9f9;
        font-size: 16px;
        line-height: 150%;
        position: relative;
        letter-spacing: 0;
        font-weight: 700;
        font-family: "Montserrat", sans-serif;
        text-transform: uppercase;

        &:hover {
          color: #EF7B8E;
          text-decoration: none !important;
        }
        &:active {
          color: #375F92;
        }

        &:after {
          position: absolute;
          width: 0;
          height: 1px;
          content: '';
          background: #fff;
          left: 0;
          right: 0;
          bottom: -5px;
          -moz-transition: all 0.4s ease-out;
          -webkit-transition: all 0.4s ease-out;
          transition: all 0.4s ease-out;
        }
      }

      &:hover .sub-menu {
        visibility: visible;
        opacity: 1;
        top: 66px;
      }
    }

    & ul.sub-menu {
      padding: 25px 30px 13px;
      & li {
        margin: 0;
        padding: 0 0 13px;
        display: block;
        & a {
          font-size: 14px;
          line-height: 1.5;
          display: inline-block;
          &:after {
            bottom: 0;
          }
        }
      }
    }
  }
`

export const submenu = css`
  /* background-color: ${(props) => (props.dark ? '#ffffff' : '#000000')}; */
  background-color: #000000;
  width: 200px;
  z-index: 9;
  text-align: center;

  transition: all ease 400ms;
  -webkit-transition: all ease 400ms;
  -moz-transition: all ease 400ms;
  visibility: hidden;
  opacity: 0;
  left: 50%;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 100px;
  transform: translateX(-50%);
  z-index: 2;

  &:before {
    content: '';
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: -5px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #000;
    z-index: 10;
  }
`

export const navigator = css`
  position: relative;
  padding: 6px 0 37px;

  & a {
    display: inline-block;
    color: #fff;
    line-height: 0.8;
    font-size: 25px;
    margin: 0;

    &:hover,
    .active {
      /* color: ${colors.blue}; */
    }

    &.search {
      margin-right: 16px;
    }
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding: 6px 0 0px;

    & a {
      &.search {
        margin-right: 10px;
      }
    }
  }
`

export const desktop = css`
  @media (max-width: 768px) {
    display: none;
  }
`;
export const mobile = css`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;
