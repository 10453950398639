/** @jsx jsx */
import { css } from "@emotion/react";

export const footerStyles = css`
  background-color: #8A588D;
  padding-top: 65px;
  padding-bottom: 70px;
  .links-title {
    display: flex;
    gap: 38px;
  }
  @media (max-width: 850px) {
    .row-logo {
      display: flex;
      flex-direction: column;
    }
    .logo-col {
      display: flex;
      justify-content: center;
      padding-bottom: 40px;
      width: 100%;
    }
    .links-title {
      padding-top: 25px;
    }
    .container {
      display: flex;
      flex-direction: column;
    }
    .social-medial-col{
      align-items: center;
      flex-direction: column-reverse;
    }
    .developed-by{
      margin-top: 56px !important;
      span {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }

  .social-medial-col {
    display: flex;
    gap: 15px;

    a {
      color: #FFF;
      font-family: Montserrat;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; 
    }

    .icons {
      display: flex;
      gap: 5px;
      
    }
  }

  .developed-by {
    justify-content: center;
    margin-top: 98px;
  }

  span {
    color:  #F1F3F4;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    display: flex;
    gap: 24px;
  }
`