/** @jsx jsx */
import { css } from "@emotion/react";


export const emocaoAPrendServStyles = css` 
  background-color:#375F92;
  padding-top: 80px;
  overflow: clip;
  scroll-margin-top: 70px;

  .title-container {
    margin-bottom: 48px;
  }

  h1 {
    color: #E8E8DB;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.8px;

    span {
      color: #BADFE5;
    }
  }
  .single-content {
    padding-bottom: 32px;
    h3 {
      color: #BADFE5;
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      letter-spacing: -0.48px;
    }
    p {
      color: #E8E8DB;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
  }
  
  img {
    padding-top: 80px;
    width: 100%;
  }
`