/** @jsx jsx */
import { css } from "@emotion/react";



export const cardStyles = css`
background-color: #fff;
border-radius: 16px;
max-width: 380px;
max-height: 280px;
min-height: 280px;
position: absolute;

padding: 55px 30px 58px 30px;

.title {
  display: flex;
}

h3 {
  color: #375F92;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.64px;
  white-space: nowrap;
}

p {
  color: #375F92;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  text-align: left;
  margin-top: -20px;
}

img {
  position: relative;
  right: -75px;
  top: -70px;
  width: 139px;
  height: 116px;
}


`
