/** @jsx jsx */
import { css } from "@emotion/react";
import shapes from '../images/LP-versar/form-shapes.png'
export const formSectionStyles = css`
  display: flex;
  flex-direction: column;
  background-color: #F3D6C8;
  padding-block: 57px;
  background-image: url(${shapes});
  background-repeat: no-repeat;
  background-size: cover;
  overflow: clip;
  scroll-margin-top: 100px;

  .form-container {
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
  }

  .form-div {
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    height: 556px;
    border-radius: 16px;
    @media (max-width: 768px) {
      margin-bottom: 32px;
    }

    h1 {
    color:  #375F92;
    text-align: center;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.8px;
    margin-bottom: 32px;
  }
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 410px;
    gap: 8px;
    border-radius: 16px;
    .text_input_containe {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
    }
    .text_input {
      background-color: #FFEFE8;
      border-radius: 8px;
      border: none;
      padding: 16px 29px;
      width: 100%;
      &::placeholder {
        color: #375F92;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.36px;
      }
    }
  }

`;

export const form = css`
align-items: center;

button {
  background-color: #EF7B8E;
  border: none;
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.32px;
  text-transform: uppercase;
  padding: 15px 56px;
}

button:hover {
  background-color: #8A588D;
}
button:active {
  background-color: #375F92;
}

.role-container {
  display: flex;
  flex-direction: column;
  span {
    color: #375F92;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.36px;
  }

  .roles-checkbox {
    background-color: #FFEFE8;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;
    max-width: 410px;
    padding: 18px 30px 40px 30px;
    width: 100%;

    span {
      margin-bottom: 16px;
    }

    .double_role {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .single-role {
      color: #375F92;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;


      .form-control {
        color: #375F92;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        display: grid;
        grid-template-columns: 1em auto;
        gap: 0.5em;
        border: none;
        background-color: transparent;
        align-items: center;
        padding: 0;
      }

      input[type="checkbox"] {
        -webkit-appearance: none;
        appearance: none;
        background-color: transparent;
        margin: 0;
          font: inherit;
        color: currentColor;
        width: 16px;
        height: 16px;
        border: 2px solid #375F92;
        border-radius: 4px;
        transform: translateY(-0.075em);
          display: grid;
        place-content: center;
      }

      input[type="checkbox"]::before {
        content: "";
        width: 12px;
        height: 12px;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em #EF7B8E;
        border-radius: 2px;
      }

      input[type="checkbox"]:checked::before {
        transform: scale(1);
      }

      .form-control + .form-control {
        margin-top: 1em;
      }
    }

    .other {
      background-color: transparent;
      outline: none;
      width: 100%;
      border: none;
      border-bottom: 2px solid #375F92;
      &::placeholder {
        color:  #375F92;
        font-family: Montserrat;
        font-size: 16px;
        font-style: italic;
        font-weight: 500;
        line-height: 150%;
      }
    }
  }
}

`;
