/** @jsx jsx */
import { css } from "@emotion/react";
import videoShape from '../images/LP-versar/stars.png'
import videoShapeMobile from '../images/LP-versar/stars-mobile.png'

export const videoInstitutional = css`
  
  background-color: #BADFE5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 76px;
  background-image: url(${videoShape});
  background-repeat: no-repeat;
  background-size: cover;
  overflow: clip;
  scroll-margin-top: 70px;

  @media (max-width: 850px) {
    background-image: none;
  }
  
  video {
    max-width: 1100px;
    @media (max-width: 850px) {
        max-width: 343px;
      margin-bottom: 100px;
    }
  }

  .video-institutional {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .title-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      color: #375F92;
      text-align: center;
      font-size: 40px;
      font-weight: 700;
      line-height: 100%;
      letter-spacing: -0.8px;
      margin-bottom: 24px;
    }
    p {
      
      color: #666;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 150%;
      max-width: 920px;

      @media (max-width: 850px) {
        max-width: 335px;
      }
    }
  }

  .text-container {
    display: flex;
    justify-content: center;
    padding-block: 52px;
    
    @media (max-width: 850px) {
      background-image: url(${videoShapeMobile});
    background-repeat: no-repeat;
    background-size: cover;
    
      p {

        max-width: 343px !important;
      }
    }

    p {
      color:#375F92;
      font-size: 40px;
      font-weight: 700;
      line-height: 120%;
      letter-spacing: -0.8px;
      max-width: 862px;
      .full-text {
        color: #8A588D;
        .socioemocional {
          color: #8A588D;
          font-size: 40px;
          font-weight: 800 !important;
          line-height: 120%;
          letter-spacing: -0.8px;
        }
      }
    }
  }
`
