/** @jsx jsx */
import { Col, Container, Row } from 'react-bootstrap'
import { jsx } from '@emotion/react'

import { VersarParaIrAlemStyles } from '../assets/styles/VersarParaIrAlem.styles'

import versarApp from '../assets/images/LP-versar/versar-mobile-app.png'

const VersarParaIrAlem = () => {
  return (
    <section css={VersarParaIrAlemStyles}>
      <Container>
        <Row>
          <Col
          data-aos="fade-right" data-aos-delay="500"
            lg={6}
            md={6}
            sm={12}
          >
            <img src={versarApp} alt='Celular com o aplicativo do versar aberto' />
          </Col>
          <Col
          data-aos="fade-left" data-aos-delay="900"
            lg={6}
            md={6}
            sm={12}
            className='text-container'
          >
            <div className='content'>
              <h2>Versar para ir além</h2>
              <p>
                O Versar é um programa socioemocional composto por projetos, conteúdos editoriais, aplicativos, Escola de Pais e formação continuada de professores. Ao reconhecer o valor estratégico das habilidades socioemocionais, a escola permite que se desenvolva um ciclo virtuoso que qualifica a gestão dentro e fora da sala de aula.
              </p>
              <a href='#contato'>Experimentar</a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default VersarParaIrAlem
