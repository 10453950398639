import React from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import OJeitoVersar from "@/components/o-jeito-versar";
import ConectandoBNCC from "@/components/ConectandoBNCC";
import VideoInstitutional from "@/components/VideoInstitutional";
import CarouselContent from "@/components/CarouselContent";
import HeaderOne from "@/components/header-one";
import VersarParaIrAlem from "@/components/VersarParaIrAlem";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import VideoSection from "@/components/VideoSection";
import EmocaoAprendServ from '@/components/EmocaoAprendServ';
import VideoHome from "@/components/video-home";
import FormSection from '../components/formSection'

const HomeOne = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Versar">
          <HeaderOne />
          <VideoHome />
          <OJeitoVersar />
          <VideoInstitutional />
          <ConectandoBNCC />
          <CarouselContent />
          <VersarParaIrAlem />
          <VideoSection />
          <EmocaoAprendServ/>
          <FormSection/>
          <Footer /> 
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  )
}

export default HomeOne;
