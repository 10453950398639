/** @jsx jsx */
import { css } from "@emotion/react";

export const videoSectionStyles = css`
margin-bottom: -10px;
video {
  height: 100%;
  max-height: 336px;
  width: 100%;
  object-fit: cover;
}

`