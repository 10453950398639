/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/react";
import { cardStyles } from '../assets/styles/CardWhatIs.styles';


const CardWhatIs = ({ icon, title, text, isNot }) => {

  return (
    <div css={cardStyles}>
      <div className='title'>
        <h3 style={{ color: isNot ? '#8A588D' : '#375F92' }}>
          {title}
        </h3>
        <img style={{ right: isNot ? '-23px' : '-75px ' }} src={icon} />
      </div>
      <div>
        <p style={{ color: isNot ? '#8A588D' : '#375F92' }}>{text}</p>
      </div>
    </div>
  )
}

export default CardWhatIs;
