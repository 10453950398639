/** @jsx jsx */
import { jsx } from '@emotion/react'
import { emocaoAPrendServStyles } from '../assets/styles/EmocaoAprendServ.styles'
import { Col, Container, Row } from 'react-bootstrap'
import studentWithTeacher from '../assets/images/LP-versar/student-with-teacher.png'

const EmocaoAprendServ = () => {
  return (
    <section id='versar-na-escola' css={emocaoAPrendServStyles}>
      <Container>
        <div data-aos="fade-down" data-aos-delay="500" className='title-container'>
          <h1>
            É emoção, é aprendizagem, é serviço: <br /> <span>é a vida dentro e fora da escola</span>
          </h1>
        </div>
        <Row data-aos="fade-left" data-aos-delay="300">
          <Col lg={6} md={6} sm={12} >
            <div className='single-content'>
              <h3>Cultura escolar</h3>
              <p>Engajamento, pertencimento e identidade</p>
            </div>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <div className='single-content'>
              <h3>Escola de Pais</h3>
              <p>Diálogo e participação dos pais na formação dos estudantes</p>
            </div>
          </Col>
        </Row>
        <Row data-aos="fade-right" data-aos-delay="400">
          <Col lg={6} md={6} sm={12}>
            <div className='single-content'>
              <h3>Agenda digital</h3>
              <p>Rotinas pedagógicas, aprendizagem estendida e monitoramento de atividades</p>
            </div>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <div className='single-content'>
              <h3>Formação de professores</h3>
              <p>Formação continuada, gestão de crises, prevenção e resolução de conflitos</p>
            </div>
          </Col>
        </Row>
      </Container>
      <img src={studentWithTeacher} alt="Crianças juntas com a professora" />
    </section>
  )
}

export default EmocaoAprendServ
