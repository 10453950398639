/** @jsx jsx */
import { css } from "@emotion/react";

export const cardStyles = css`
max-width: 250px;
width: 100%;

@media (max-width: 850px) {
  max-width: 343px;
  img {
    width: 100%;
  }
  h3, p {
    text-align: center;
  }
}

h3 {
  color: #8A588D;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.72px;
  margin-top: 40px;
  margin-bottom: 24px;
}
p {
  color: #375F92;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 160%;
}
  
`
