/** @jsx jsx */
import { css } from "@emotion/react";

export const notfound = css`
background-color: #2F382B;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 454px;
margin-top: 56px;
h1{
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 56px;
  line-height: 100%;
  @media (max-width: 768px) {
    font-size: 32px;
  }
}
p{
  color: #ffffff;
  font-size: 18px;
  @media (max-width: 768px) {
    text-align: center;
  }
}
`

export const success = css`
background-color: #BADFE5;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: calc(100vh - 421px);
margin-top: 119px;
h1{
  text-align: center;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 56px;
  line-height: 100%;
  @media (max-width: 768px) {
    font-size: 32px;
  }
}
p{
  font-size: 18px;
  @media (max-width: 768px) {
    text-align: center;
  }
}
`;